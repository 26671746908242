* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: -0.025em;
}

.App {
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.content_wrapper {
  position: relative;
  min-height: calc(100vh - 73px);
}

.nav_wrapper {
  padding: 0.5rem 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #D9D9D9;
  justify-content: space-between;

  .call_btn {
    border: none;
    background: none;
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #666;
    height: 50px;
    padding-right: 2rem;

    img {
      width: 25px;
      height: 25px;
    }

    p {
      height: 70px;
      line-height: 85px;
      padding-left: 6px;
      font-family: 'Outfit', sans-serif;
      font-weight: 700;
      font-size: 20px;
    }

    em {
      top: 1px;
      position: absolute;
      left: 50%;
      background: #bd2333;
      color: #fff;
      font-size: 11px;
      text-align: center;
      border-radius: 15px;
      padding: 2px 10px;
      white-space: nowrap;
      font-style: normal;
      letter-spacing: 0px;
      font-family: 'Outfit', sans-serif;
      font-weight: 700;
    }
  }

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0 2rem;

    li {
      margin-left: 2rem;

      a {
        color: #666;
        font-weight: 500;
        text-decoration: none;
        font-size: 15px;
      }
    }
  }
}


.btn_list_wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;

  ul {
    width: 100%;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      float: left;
      flex: 0 0 25%;
      width: 25%;
      padding: 0 8px;
      transition: all 0.5s ease;

      &.mobile_last_btn {
        display: none;
      }

      &:nth-child(odd) {
        padding-top: 40px;
      }

      &:hover {
        margin-top: -40px;
      }

      a {
        position: relative;
        width: 100%;

        img {
          object-fit: cover;
          width: 100%;
          height: 500px;
        }

        .btn_desc {
          position: absolute;
          left: 32px;
          bottom: 64px;
          color: #fff;

          span {
            display: block;
            font-weight: bold;
            line-height: 1.2;

            &:first-child {
              font-size: 20px;
            }

            &:last-child {
              margin-top: 1rem;
              font-size: 27px;
            }
          }
        }
      }
    }
  }
}


@media (max-width: 880px) {
  .nav_wrapper {
    justify-content: space-between;
    position: relative;
    padding: 0.7rem 1.5rem !important;

    .call_btn {
      padding-right: 0 !important;

    }

    .nav_links {
      display: none;
    }

  }
  .btn_list_wrapper {
    height: 100%;
    margin-top: 2rem;

    ul {
      li {
        width: 50%;
        flex: 0 0 50%;

        &:hover {
          margin-top: 0;
        }

        &.mobile_last_btn {
          display: block;
          width: 100%;
          flex: 0 0 100%;

          a {

            img {
              max-height: 164px;
            }
          }
        }

        &:nth-child(odd) {
          padding-top: 0;
        }

        a {

          img {
            max-width: 100%;
            height: auto;
            border-radius: 20px;
          }

          .btn_desc {
            bottom: 30px !important;
            left: 20px !important;

            span {
              &:first-child {
                font-size: 12px !important;
              }

              &:last-child {
                font-size: 21px !important;
              }
            }
          }
        }
      }
    }
  }
}